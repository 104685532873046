import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { FulifillmentDate } from './FulfillmentDate';
import { PrinterModal } from './PrinterModal';
import { Box } from '../../../../components/Box';
import { Tooltip } from '@bottomless/common/components';

export const Batch = ({ batch, isFedexVendor }) => {
  const [opened, setOpened] = useState(false);

  const getLabels = ({ layout }) => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${batch._id}/labels?layout=${layout}`
    );
  };

  const getScanForm = useCallback(() => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${batch._id}/scan-form`);
  }, [batch]);

  const getCsv = useCallback(() => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${batch._id}/csv`);
  }, [batch]);

  const isLocalBatch = useMemo(() => batch.batch_id.find(batchId => batchId.includes('local')), [batch]);

  const isShopify = useMemo(() => batch.batch_id.find(batchId => batchId.includes('shopify')), [batch]);

  const menuItems = useMemo(
    () => [
      ...(!isLocalBatch ? [{ label: 'Get labels', action: () => setOpened(true) }] : []),
      ...(!isFedexVendor && !isShopify
        ? [
            {
              label: (
                <div className="d-flex align-items-center">
                  Get scan form
                  {!batch.scan_form && (
                    <div className="ml-2">
                      <Tooltip content="Scan form isn't available yet, please try again later" id={batch._id}>
                        <span className="info-dot info-dot-gray">i</span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              ),
              action: getScanForm,
              disabled: !batch.scan_form,
              className: !batch.scan_form ? 'pointer-events-visible' : undefined,
            },
          ]
        : []),
      { label: 'Get CSV', action: getCsv },
    ],
    [isLocalBatch, isFedexVendor, isShopify, batch, getScanForm, getCsv]
  );

  return (
    <>
      {batch.batch_id.length > 0 && (
        <>
          <Box menuStyle="buttons" menuItems={menuItems}>
            <div>
              <FulifillmentDate dateFulfilled={batch.created_at} />
              {batch.batch_id.map(batchId => (
                <div key={batchId} className="text-secondary">
                  Batch: {batchId}
                </div>
              ))}
              <div className="small text-secondary">
                {batch.orders.length} order{batch.orders.length > 1 ? 's' : ''}
              </div>
            </div>
          </Box>
          <PrinterModal onSubmit={getLabels} opened={opened} onClose={() => setOpened(false)} />
        </>
      )}
    </>
  );
};

Batch.propTypes = {
  batch: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    batch_id: PropTypes.array.isRequired,
    created_at: PropTypes.string.isRequired,
    scan_form: PropTypes.string,
    orders: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isFedexVendor: PropTypes.bool,
};

Batch.defaultProps = {
  isFedexVendor: false,
};
